import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import conciseAddress from '../../Utils/ConciseAddress';
import { GoArrowLeft } from 'react-icons/go';
export default function DirectDetails() {
    const navigate = useNavigate();
    
    const location = useLocation();
    const userPersonalInviteDetails = location.state;
    const [useList, setUserList] = useState([])
    const [search, setSearch] = useState('');

    useEffect(() => {
       setUserList(userPersonalInviteDetails);
    }, [userPersonalInviteDetails, location.state])

    const filterData = useList.filter((data) => {
        return data?.username?.includes(search) ||
            data?.walletaddress?.includes(search) ||
            data?.email?.includes(search) ||
            data?.totalStake_amount?.includes(search) ||
            data?.total_earn?.includes(search) 
    })

    return (
        <div className="referral_container">
            <div className="referralTable">
                <div className="refferal_header">

                    <p>
                        <span onClick={() => navigate(-1)}>
                            <GoArrowLeft fontSize={27} />
                        </span>
                        <span className='ms-3'>Personal Invite Details</span>
                    </p>
                </div>

                <div className='d-flex justify-content-end flex-wrap gap-2 align-items-center'>
                    <input type="text" placeholder='Search' value={search} onChange={(e) => { setSearch(e.target.value) }} />
                </div>
                <div className='w-100 responsiveTableParent'>
                    <table className='w-100'>
                        <thead className='affTableHeader'>
                            <tr>
                                <th className='boder-Rght'>Username</th>
                                <th className='boder-Rght'>Email</th>
                                <th className='boder-Rght'>Wallet Address</th>
                                <th className='boder-Rght'>Total Stakes</th>
                                <th className='boder-Rght'>Total Earn</th>
                                <th className='boder-Rght'>Status</th>
                            </tr>
                        </thead>
                        <tbody className='referralTableBody'>
                            {filterData.length > 0 ? (
                                filterData.map((referral) => (
                                    <tr key={useList?._id}>
                                        <td>{referral?.username}</td>
                                        <td>{referral?.email}</td>
                                        <td>{conciseAddress(referral?.walletaddress)}</td>
                                        <td>{Number(referral?.totalStake_amount)?.toFixed(2)}</td>
                                        <td>{Number(referral?.total_earn)?.toFixed(2)}</td>
                                        <td>{referral?.verified === true ? "Verified" : "Not Verified"}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className='data_not fw-bold'>No Data Available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {/* <div className='w-100 d-flex justify-content-between'>
                <span className='fw-bold'>Showing {activePageNo} to {totalPageNo} of {totalPageNo} Entries</span>
                <div className='AffTxTableButton'>
                <button disabled={activePageNo === 1} onClick={() => handlePageNumber(activePageNo - 1)}>
                    <span>Prev</span>
                </button>
                {Array.from({ length: totalPageNo }, (_, index) => (
                    <button
                    className={`${activePageNo === index + 1 ? 'activePaginationbtn' : ''}`}
                    key={index}
                    onClick={() => handlePageNumber(index + 1)}
                    >
                    {index + 1}
                    </button>
                ))}
                <button disabled={activePageNo === totalPageNo} onClick={() => handlePageNumber(activePageNo + 1)}>
                    <span>Next</span>
                </button>
                </div>
            </div> */}
            </div>
        </div>
    );
}